@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

:root {

  /* == Colores ==*/
  --primary: #4DA6DE;
  --secondary: #27348B;
  --white: #FFF;
  --grey: #55575A;

  /* == Tipografías ==*/
  --font-family: 'Cabin', sans-serif;

  /*== Jerarquía de textos ==*/   
  --regular-weight: 400;
  --medium-weight: 600;
  --bold-weight: 700;

  --bs-heading-color: var(--grey);
  --bs-body-color: var(--grey);

}

body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--grey);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--bold-weight);
}

th {
  font-weight: var(--bold-weight);
}