/* Contenedor principal del sidemenu */
.sidemenu-container {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Asume que quieres que el sidemenu ocupe toda la altura de la vista */
    padding: 1rem; /* Ajusta el padding según necesites */
    top: 0;

    p, h6 {
      font-size: 14px;
    }
  }

/* si la pantalla es 800 o menos */
@media screen and (max-width: 1024px) {
    /*hago el body no scrolleable*/
  .sidemenu-container {
    background: white;
    display: none;
  }
}
  
  /* Contenedor para el logo y el título, asumo que quieres que estén juntos */
  .logo-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 10px 6rem; /* Añade espacio entre esta sección y la siguiente */
  }
  
  /* Datos del usuario */
  .user-info {
    > div {
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    gap: 10px;
    cursor: pointer;
    transition: all .2s ease;

    svg, path, h6 {
      transition: color .1s ease, fill .1s ease;
    }
  }

  .user-info:hover .user-info-text {
    color: var(--primary);
  }

  .user-info:hover svg path {
    fill: var(--primary);
  }

  .user-info div svg {
    min-width: 30px;
  }

  .close-section .icon{
    display: none;
  }
  
  /* Texto del usuario */
  .user-info div p {
    margin: 0px; /* Elimina el margen predeterminado */
  }

  .sidemenu-btns{
    margin-top: auto;
  }

  .icon svg{
    vertical-align: sub;
    min-width: 30px;
    margin-right: 5px;
  }
  
  /* Botones */
  .sidemenu-container button {
    width: 100%; /* Hace que los botones ocupen todo el ancho del contenedor */
    padding: 0.5rem; /* Añade algo de padding para hacerlos más grandes */
    margin-top: 0.5rem; /* Añade espacio entre los botones si están uno encima del otro */
    border: none; /* Elimina el borde predeterminado */
    border-radius: 4px; /* Opcional: redondea las esquinas */
    background-color: #0000FF; /* Cambia al color que desees para el fondo */
    color: white; /* Cambia al color que desees para el texto */
    cursor: pointer; /* Cambia el cursor para indicar que es un botón */
  }
  
  .sidemenu-container button:hover {
    background-color: #27348B; /* Un color ligeramente más oscuro para el efecto hover */
    color: white;
  }