
.info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 20px;
  background: linear-gradient(180deg, #36D0FF 0%, #27348B 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
  border-radius: 5px;
  color: white;
  height: 166px;
}

.info-box__text {
    color: #FFF;
    font-family: Cabin;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.info-box__number {
    color: #FFF;
    font-family: Cabin;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.info-box__text-secondary {
    color: #FFF;
    font-family: Cabin;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
