.patia-container-background {
  background: linear-gradient(to right, var(--primary) 42%, #ffffff 36%);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patia-icon {
  width: auto;
  height: 100%;
  left: 0;
  position: fixed;
}

.patia-login-container {
  background: #4da6de;
  z-index: 1;
  display: flex;
  height: 65vh;
  min-height: 600px;
  min-width: 57.4vw;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.patia-login-background {
  background: #4da6de; /* Sustituye con el color exacto de tu marca */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36%;
}

/* Asegúrate de ajustar el tamaño del logo SVG aquí */
.patia-logo-container svg {
  width: 100%; /* o el tamaño que prefieras */
  height: auto;
}

.patia-login-form-container {
  background:var(--white);
  display: grid;
  flex: 2 1;
}

.patia-password-recovery {
  align-self: end;
  margin-bottom: 25px;
  font-size: 12px;
  color: var(--grey);
  text-decoration: none;
}

.patia-form-subtxt{
  font-size: 12px;
  color: var(--grey);
  text-decoration: none;
}

.btn-subtxt{
  padding: 5px 25px;
  margin-top: 5px;
  font-size: 12px;
  background: #55575A;
}

.patia-need-help {
  font-size: 12px;
  color: var(--gray);
  text-decoration: none;
  font-family: var(--font-family);
  justify-self: end;
  padding: 31px 18px 0 0;
}

.patia-btn-secondary {
  background: var(--secondary);
}

.patia-form {
  max-width: 310px;
  width: 90%;
}

.patia-form-user{
  justify-self: center;
}

.patia-form input {
  width: 100%;
  margin-bottom: 1rem;
  border: 0px solid var(--white);
  border-bottom: 1px solid var(--grey);
  color: var(--grey);
  font-weight: 700;

  &:focus {
    outline: none;
  }
}

.patia-form-input {
  position: relative;
  width: 100%;

  label {
    color: var(--grey);
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    left: 0px;
    top: 9px;
    pointer-events: none;
    transition: 0.2s;
    line-height: 12px;
  }
}

.patia-form-input input:focus + label,
.patia-form-input  input:valid + label{
    top: -13px;
    left: 2px;
    font-size: 11px;
}

.patia-form input:focus + label,
.patia-form  input:valid + label{
    top: -13px;
    left: 2px;
    font-size: 11px;
}

.patia-form-h1 {
  color: #55575A;
  text-align: center;
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.patia-input-text {
  color: var(--grey);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.patia-form-container {
  margin: auto;
  width: 100%;
  align-items: flex-start;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.patia-form button {
  width: 60%;
  max-width: 30vw;
  float: right;
  padding: 0.5rem;
  outline: none;
  cursor: pointer;
  margin-left: auto;

  border-radius: 5px;
  border: 1px solid var(--primary);
  background: #1EA4DC;

  color: var(--white);
  text-align: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.patia-login-form-container button{
  width: 12.5rem;
}

.patia-subheading {
  font-size: 20px;
}

.patia-subheading-p {
  font-size: 12px;
  color: var(--grey);
}

.effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0;
  overflow: hidden;
  min-width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  z-index: 1;
}

.effect.effect-1 {
  transition: all 0.15s linear 0s;
  
  &:before {
    content: "\f30b";
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.15s linear 0s;
  }
  
  &:hover {
    text-indent: -20px;
    
    &:before {
      right:0;
      opacity: 1;
      text-indent: 0px;
    }
  }
}

.effect.effect-2 {
  transition: all 0.15s linear 0s;
  
  &:before {
    content: "\f176";
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: -10px;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.15s linear 0s;
    text-indent: 0px;
  }
  
  &:hover {
    text-indent: -20px;
    
    &:before {
      opacity: 1;
      bottom: 0;
    }
  }
}


.effect.effect-3 {
  transition: all 0.15s linear 0s;
  
  &:before {
    content: "\f177";
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5px;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.15s linear 0s;
  }
  
  &:hover {
    text-indent: 20px;
    
    &:before {
      left:0;
      opacity: 1;
      text-indent: 0px;
    }
  }
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
  font-size: 12px;

  &:hover {
    background: #636db2 !important;
    border-color: #636db2 !important;
    color: white;
  }
}

.btn-secondary {
  background: var(--secondary);
  border-color: var(--secondary);
  color: white;
  font-size: 12px;

  &:hover {
    background: #112060 !important;
    border-color: #112060 !important;
    color: white;
  }
}

.btn-terciary {
  background: var(--grey);
  border-color: var(--grey);
  color: white;
  font-size: 12px;

  &:hover {
    background: #5a5855 !important;
    border-color: #5a5855 !important;
    color: white;
  }
}

.btn-prev {
  background: rgba(91, 91, 91, .5);
  border: none;
  color: white;
  font-size: 12px;

  &:hover {
    background: rgba(91, 91, 91, .7) !important;
    border-color: rgba(91, 91, 91, .7) !important;
    color: white;
  }
}

@media (max-width: 1024px) {
  .patia-container-background {
    background: #4da6de;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .patia-login-container {
    background: transparent;
    box-shadow: none;
    height: 90vh;
    width: 90vw;
    display: unset;
  }

  .patia-login-background {
    background: transparent;
    align-items: start;
  }

  .patia-login-form-container {
    border-radius: 0.3125rem;
    background: #fff;
    box-shadow: 0px 0px 49px -2px rgba(0, 0, 0, 0.5);
    padding: 20px 20px 70px 20px;
  }

  .patia-form-container {
    width: 70%;
  }

  .patia-logo-container{
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .patia-form button {
    width: 100%;
  }
  .patia-form-h1 {
    font-size: 20px;
  }
}

.patia-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.patia-success {
  color: var(--primary);
  font-size: 12px;
  margin-top: 5px;
}