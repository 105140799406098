.input-group {
    input {
        transition: all 0.2s;
    }
    svg {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 22px;
        cursor: pointer;
        transition: all 0.2s;
        z-index: 5;
    }
}

.active-input {
    border: 1px solid #dee2e6 !important;
    margin-top: 0.5rem;
}