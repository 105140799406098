.filter-search{
    margin-right: -2px;
    height: 30px;

    &:active, &:focus, &:hover{
        outline: none;
        box-shadow: none;
        z-index: 1 !important;
    }
}

.search-button {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        color: var(--primary) !important;
    }
}

.filter-button{
    border-radius: 0px 50px 50px 0px;
}

.patia-search-input{
    margin-left: auto !important;
    float: right;
}

.filter-title{
    font-size: 25px;
    line-height: 9px;
    margin: 0;
}

.filter-subtitle{
    margin-top: auto;
    font-size: 14px;
    line-height: 10px;
    text-transform: uppercase;
}

.subtitle-orange{
    color: #FF8A00;
    font-weight: bold;
}

.subtitle-blue{
    color: #1EA4DC;
    font-weight: bold;
    margin-bottom: 0;
}