.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-landing{
    max-width: 96vw;
}

.logo-container svg {
    width: 150px; /* o el tamaño que prefieras */
    height: auto;
    margin: auto;
    margin-top: 2.12rem;
}

.landing-button {
    float: right;
    width: 110px;
    line-height: 15px;
    height: 30px;
    border: none;
    border-radius: 4px;
    background-color: #4DA6DE; /* Color del botón, usa el que corresponda a tu diseño */
    color: white;
    cursor: pointer;
    margin-left: auto;
    margin-top: 3.12rem;
}

.button-big {
    width: 188px;
    line-height: 26px;
    height: 53px;
    float: left;
    margin: 5px;
    font-size: 16px;
}

.background-icon{
    position: absolute;
    z-index: -1;
    width: auto!important;
    height: 100%;
    top: 0;
    left: 0;
}

.container-icon{
    position: relative;
    padding: 0 14px;
}

.landing-first-image{
    width: 100%;
}

.landing-second-image{
    width: 100%;
    margin-top: 40px;
}

.landing-blue-box{
    background-color: #1EA4DC;
    text-align: center;
    border-radius: 0px 0px 15px 15px;
    color: white;
    margin-top: -120px!important;
    padding-top: 95px;
    padding-bottom: 85px;
    position: relative;
    z-index: -2;
}

.landing-form{
    /*margin: 4rem!important;*/
    /*margin-top: 7rem!important;*/
    margin-bottom: 0;
    margin-top: 30px !important;
}

.landing-form input{
    border: 0px solid var(--white);
    border-bottom: 1px solid var(--grey);
    border-radius: 0;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
}

.landing-form input:focus{
    border: 0px solid var(--white);
    border-bottom: 1px solid var(--grey);
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
}

.landing-form input::placeholder{
    color: transparent;
}

.landing-form label{
    position: absolute;
    color: var(--grey);
    top:10px;
    transition: all 0.3s ease;
}

.landing-form label.active{
    top: -11px;
    font-size: 12px;
    color: var(--grey);
    z-index: 2;
}

.landing-form textarea{
    margin-top: 25px;
    border: 0px solid var(--white);
    border-bottom: 1px solid var(--grey);
    border-radius: 0;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
}

.landing-form textarea:focus{
    border: 0px solid var(--white);
    border-bottom: 1px solid var(--grey);
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
}

.landing-form button{
    background: #55575A;
    color: white;
    width: 90px;
    line-height: 15px;
    height: 30px;
}

.landing-form button:hover{
    background: #27348B;
    color: white;
}

.image-header-landing{
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.image-header-landing p{
    margin-bottom: 0;
    font-size: 13px;
}

.landing-blue-box h2{
    color: white;
    font-size: 18px;
    width: 85%;
    margin: auto;
}

.landing-blue-box p{
    color: white;
    font-size: 12px;
}

.landing-title{
    font-size: 45px;
    font-weight: normal!important;
    line-height: 1.0;
}

.landing-title .txt-blue{
    color: #1EA4DC;
}

.footer-links{
    color: var(--grey);
    text-decoration: none;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
}

.footer-self-end{
    place-self: self-end;
}

.footer-content-start{
    display: flex;
}

@media screen and (min-width: 1280px) {
    .landing-air{
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    .landing-form{
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    .image-header-landing p{
        font-size: 20px;
        font-weight: 600;
    }
    .button-big {
        width: 149px;
        line-height: 21px;
        height: 42px;
        float: left;
        margin: 5px;
    }
    .landing-blue-box h2{
        font-size: 45px;
        width: 70%;
    }
    .landing-title{
        font-size: 70px;
    }
    .landing-blue-box{
        margin-top: -190px!important;
        padding-top: 155px;
        padding-bottom: 145px;
    }
}

@media screen and (min-width: 750px) {
    .landing-form{
        margin-top: 40px !important;
    }
    .landing-blue-box h2{
        font-size: 30px;
        width: 80%;
    }
    .landing-blue-box p{
        font-size: 14px;
    }
    .col-icon{
        padding: 0 5px!important;
    }
    .landing-title{
        font-size: 60px;
    }
    .landing-blue-box{
        margin-top: -170px!important;
        padding-top: 170px;
        padding-bottom: 125px;
    }
}

@media screen and (max-width: 1024px) {
    .landing-blue-box{
        margin-top: -100px !important;
        padding-top: 100px;
        padding-bottom: 125px;
    }

    .landing-title {
        font-size: 40px;
    }
}

.col-icon {
    a {
        width: 100%;
        height: 100%;

        svg path {
            transition: fill 0.2s;
        }

        &:hover {
            svg path {
                fill: #2A3585;
            }
        }
    }
}