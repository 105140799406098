.FullBodyIMG {
  display: none;
  background: white;
  z-index: 3;
}

/* si el ancho es 800 o menos */
@media screen and (max-width: 767px) {
    /*hago el body no scrolleable*/
    .FullBodyIMG {
        height: 100vh;
        width: 100vw;
        align-items: center;
        display: grid;
        position: fixed;
        left: 0;
    }

    .FullBodyIMG svg {
        margin: 0 auto;
    }

    .FullBodyIMG div{
        text-align: center;
    }

    .container-dashboard{
        overflow: hidden;
    }

}