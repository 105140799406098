.container-dashboard {
    background: #F8F8F8;
}

.container-dashboard .btn:hover{
    background: #27348B;
    color: white;
}

.dashboard-box{
    margin-left: auto;
    min-height: 100vh;
    background-color: #f8f8f8;
}

.dashboard-box .card{
    margin-right: 5px;
    min-height: 95vh;
}