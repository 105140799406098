.page-item-p{
    color: #55575A4D;
    font-size: 12px;
    margin-right: auto;
    cursor: pointer;
    min-width: 30px;
}

.page-item-p:hover{
    color: var(--primary);
}

.page-item-n{
    color: #55575A4D;
    font-size: 12px;
    margin-left: auto;
    cursor: pointer;
    min-width: 30px;
}

.page-item-n:hover{
    color: var(--primary);
}