
.table-box{
    max-height: 0;
    overflow: hidden;
    transition: max-height 2s ease-out;
    background-color: #E3E3E3;
    color:#55575A;
}

.table>:not(caption)>*>* {
    color:#55575A;
}

.table {
    th:first-of-type {
        border-left: none;
    }

    th:last-of-type {
        border-right: none;
    }
}

.dashboard-table {
    text-align: center;
    margin-bottom: 0;
}

.table-red-color{
    color: #FF8A00 !important;
}

.dashboard-table th {
    text-transform: capitalize;
}

.table-box.loaded {
    overflow: auto;
    max-height: 10000px;
    transition: max-height 2s ease-in;
}

.table-box tr.today {
    td {
        color: var(--primary);

        svg {
            path {
                fill: var(--primary);
            }
        }
    }
}

.table-box::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border: 1px solid white;
    border-radius: 8px;
}

.table-box::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: white;
    border: 3px solid white;
}

.table-box::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #495057;
    border: 3px solid #fff;
}

/*si es menor de 1024px*/
@media screen and (max-width: 1600px) {
    .table-box.loaded {
        overflow-x: auto;
    }
}

.checkbox-container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
}

/* menos de 1024px */
@media screen and (max-width: 1024px) {
    .dashboard-table {
        font-size:12px
    }
}

/* menos de 850px */
@media screen and (max-width: 850px) {
    .dashboard-table {
        font-size:10px
    }
}