.table-box th {
    background-color: #E3E3E3;
    padding: 5px 20px;
    color: #55575A;
    font-weight: 700;
    font-size: 12px;
    vertical-align: middle;
    white-space: nowrap;
}

.table-box td {
    padding: 5px 20px;
    background-color: white;
    border-color: #E3E3E3;
    font-size: 12px;
    vertical-align: middle;
    white-space: nowrap;
    transition: all .2s ease;
}

.table-box td:nth-child(-n+4) {
    text-align: left;
}

.table-box th:nth-child(-n+4) {
    text-align: left;
}

.table-box td[role="button"]:hover svg path {
    fill: var(--primary);
}

.table-box {
    width: 100%;
    border-right: #e3e3e3;
    border-right-width: 1px;
    border-right-style: solid;
}