.btn-back {
    background: #1EA4DC;
    border-radius: 0.3125rem;
    color: white;
    font-size: 12px;
    padding: 3px 20px;
}


.action-btn {
    background: #1EA4DC;
    border-radius: 0.3125rem;
    color: white;
    margin-right: 24px;
    border-color: white;
}

.action-btn:hover {
    background: var(--secondary);
    border-radius: 0.3125rem;
    color: white;
}

.list-action-icon {
    margin-bottom: 2px;
}

.genome-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.genome-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    max-height: 80%;
    min-width: 50%;
    overflow: auto;
}

.genome-popup-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border: 1px solid white;
    border-radius: 8px;
}

.genome-popup-content::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: white;
    border: 3px solid white;
}

.genome-popup-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #495057;
    border: 3px solid #fff;
}


.clickable-text {
    cursor: pointer;
    font-size: 14px;
    color: #55575A;
}

.clickable-text:hover {
    color: var(--primary);
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 35px;
    padding: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #4DA6DE;
    margin: 1rem auto 0 auto;
    --_m: 
      conic-gradient(#0000 10%,#4DA6DE),
      linear-gradient(#4DA6DE 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }

  .loader-form {
    width: 13px;
    padding: 3px;
    margin: 0 7px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #fff;
    --_m: 
      conic-gradient(#0000 10%,#fff),
      linear-gradient(#fff 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

  .page-item.active {
    color: #55575A4D;
  }

  .page-item-p, .page-link-n {
    font-weight: 700;
    font-size: 12px !important;
    font-family: 'Helvetica Neue', sans-serif;
    cursor: pointer;
    
    &:disabled {
        color: #55575A4D;
    }
  }

  /* Style the scrollbar track */
*::-webkit-scrollbar {
    width: 7px; /* width of the scrollbar */
    height: 7px; 
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary); /* color of the handle */
    border-radius: 10px; /* rounded corners of the handle */
}

.tabla-subidas {
    width: 100%;

    tr {
        td:first-of-type {
            background-color: #E3E3E3;
            font-size: 14px;
            border-bottom: 1px solid white;
            vertical-align: middle;
            white-space: nowrap;
            height: 42px;
            padding-left: 12px;
            padding-right: 12px;
            font-weight: 600;
        }

        td:last-of-type {
            font-size: 14px;
            border-bottom: 1px solid #E3E3E3;
            border-right: 1px solid #E3E3E3;
            border-top: 1px solid #E3E3E3;
            vertical-align: middle;
            white-space: nowrap;
            height: 42px;
            padding-left: 12px;
            padding-right: 12px;
            font-weight: 600;
        }
    }
}