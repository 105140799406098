.btn-sendfiles {
    place-self: end;
    background: #1EA4DC;
    border-radius: 0.3125rem;
    color: white;
    min-width: 130px;
}

.sendfiles-endbtn{
    display: flex;
}

.input-file-container {
    input[type="file"] {
        padding: 5px 10px;
        height: 30px;
        font-size: 12px;
    }
}

.progressInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    
    span {
        font-size: 20px;
        font-weight: 700;
        color: var(--primary);
    }

    p {
        font-size: 20px;
        font-weight: 700;
        color: var(--terciary);
    }
}

.progress-container {
    border-radius: 10px;

    .progress {
        width: 100%;
    }

    .progress-bar {
        background: rgb(30,164,220);
        background: linear-gradient(90deg, rgba(30,164,220,1) 0%, rgba(30,164,220,0.8267682072829132) 78%, rgba(227,227,227,1) 100%);
        transition: all 1s ease;
    }
}

.upload-error {
    color: red;
}