.patia-welcome-container {
    padding-top: 100px;

    .patia-welcome-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, 1fr);

            .patia-welcome-item {
                &:nth-child(4) {
                    display: none;
                }
                &:nth-child(5) {
                    order: 1;
                }
            }
        }

        .patia-welcome-item {
            padding: 3rem;
            position: relative;

            p {
                font-size: 14px;
                font-weight: 400;
                color: var(--gray);
                z-index: 1;
            }

            img {
                position: absolute;
                height: 70%;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
            }

            &:nth-child(-n+3)&::before {
                content: "";
                position: absolute;
                top: 7px;
                right: 0;
                margin: auto;
                width: 100%;
                height: 2px;
                background: linear-gradient(to right, transparent, #E3E3E3, #1EA4DC);
                display: block;
            }

            &:nth-last-child(-n+3)&::before {
                content: "";
                position: absolute;
                top: 7px;
                right: 0;
                margin: auto;
                width: 100%;
                height: 2px;
                background: linear-gradient(to left, transparent, #E3E3E3, #1EA4DC);
                display: block;
            }

            &:nth-child(4)&::before {
                display: none;
            }
        }
    }
}

.patia-welcome-container {
    padding: 5% 10%;

    .patia-welcome-item {
        padding-left: 9%;
        padding-top: 5%;
        font-size: 14px;

        .patia-welcome-item-content {
            height: 300px;
            overflow-y: auto;
            padding-right: 10px;

            &::-webkit-scrollbar-thumb {
                background-color: #D9D9D9;
            }
        }

        img {
            position: absolute;
            height: 280px;
            top: 0;
            left: 0;
            bottom: 0;
            margin: 0;
            z-index: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 20px;
            right: 0;
            margin: auto;
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, transparent, #E3E3E3, #1EA4DC);
            display: block;
        }
    }

    .subtitle {
        font-family: 'Cabin', sans-serif;
        font-size: 20px;
        margin-bottom: 2rem;
    }

    .step-span {
        font-size: 15px;
        color: var(--primary);
        font-weight: bold;
        font-family: 'Cabin', sans-serif;
    }

    .patia-welcome-title  {
        font-size: 30px;
        font-weight: bold;
        font-family: 'Cabin', sans-serif;
        color: var(--primary);
        margin-bottom: 1rem;
        margin-top: 5px;
    }

    ol {
        padding-left: 3rem;

        li {
            padding-left: 8px;
            margin-bottom: 10px;
        }

        li::marker {
            color: var(--primary);
        }

        ol {
            li:marker {
                color: var(--terciary);
            }
        }
        
    }

    ul > li {
        list-style-type: disc;
    }
}

.slide.animateIn {
    transition: all 1s ease !important;
}

.animateOut {
    transition: all 1s ease !important;
}

.slider {

    .previousButton {
        top: 95% !important;
        left: 0 !important;
        transition: all 0.3s ease !important;

        &:hover{
            transform: scale(1) translateY(-50%) !important;
        }
    }

    .nextButton {
        top: 95% !important;
        right: 0 !important;
        transition: all 0.3s ease !important;

        &:hover{
            transform: scale(1) translateY(-50%) !important;
        }
    }
}

.slide h3 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide .step-span {
	transition: all 0.1s ease;
	-webkit-transform: translateX(-20px);
					transform: translateX(-20px);
	opacity: 0;
}

.slide .btn {
	transition: all 0.3s ease;
	-webkit-transform: translateY(50px);
					transform: translateY(50px);
	opacity: 0;
}

.slide p, .slide ol *, .slide ul *{
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide.animateIn.previous h3,
.slide.current h3,
.slide.animateIn.next h3,
.slide.animateIn.previous .btn,
.slide.current .btn,
.slide.animateIn.next .btn,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous ol *,
.slide.current ol *,
.slide.animateIn.next ol *,
.slide.animateIn.previous ul *,
.slide.current ul *,
.slide.animateIn.next ul *{
	-webkit-transform: translateX(0) !important; 
	        transform: translateX(0) !important;
	-webkit-transition-delay: .7s !important;
					transition-delay: .7s !important;
					opacity: 1 !important;
}

.slide.animateIn.previous .step-span,
.slide.current .step-span,
.slide.animateIn.next .step-span{
	-webkit-transform: translateX(0) !important; 
	        transform: translateX(0) !important;
	-webkit-transition-delay: .4s !important;
					transition-delay: .4s !important;
					opacity: 1 !important;
}

.slide.current button.upload-report {
    -webkit-transition-delay: unset !important;
    transition-delay: unset !important;
}

@media (max-width: 768px) {
    .slider .previousButton, .slider .nextButton {
        display: none !important;
    }
}